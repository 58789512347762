// i18next-extract-mark-ns-start capabilities-thermal

import { LINKS, THERMAL_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';

import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilityThermalPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Thermal management of IC engines and vehicle systems</h2>

						<p>
							Temperature management is an essential part of optimising any Internal Combustion Engine (ICE)
							as well as predicting complete vehicle system performance including heat exchangers and cooling fans.
						</p>
						<p>
							With the focus shifting to electric and hybrid electric vehicles, thermal analysis now extends beyond
							traditional ICE to include their performance with alternative e-fuels (including hydrogen)
							and their use as part of hybrid propulsion systems.
							These developments require R&D resources to improve overall efficiency,
							whilst reducing engine emissions, friction, oil consumption and Noise, Vibration, Harshness (NVH).
						</p>
						<p>
							The heat generated by an ICE has a significant effect on both the performance of the system and its structural integrity.
							Accurate prediction of temperatures throughout a powertrain structure is vital for the successful design of the
							engine components and of the overall system.
							Component failure due to thermal loading at the prototype stage is not only costly but could impact on the time scales
							for delivering the engine.
							Key focus areas for thermal analysis include warm-up from cold to optimum conditions, powertrain integrity,
							piston performance and durability.
						</p>
						<p>
							Similarly for hybrid and electric systems, both solid and fluid components can be modelled to develop an
							effective cooling system and improve the heat flow to heat exchanger packs.
						</p>
						<p>
							In the initial stages of development, physical prototype modelling is expensive, requiring virtual simulation tools to answer many of the
							'What ifs' before committing to a chosen design path.
							Detailed thermal models are vital in the assessment overall performance and reducing emissions, friction, oil consumption and fuel usage.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_ThermalEfficiency")}
						className="widget__sibling__pages">
						<AssetList>
							{THERMAL_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default CapabilityThermalPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-thermal", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/thermal/Realis-Simulation-Thermal.jpg" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { capabilities: { in: "Thermal" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
